import React, { useEffect, useState } from "react";
import client from "../../services/core/limboclient";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import styles from "../therapy-tests/index.module.scss";
import BorderColorTwoToneIcon from "@mui/icons-material/BorderColorTwoTone";
import { useNavigate } from "react-router-dom";

const columns = [
  { id: "firstName", label: "Ім'я" },
  { id: "lastName", label: "Прізвище" },
  { id: "rate", label: "Тариф" },
  { id: "share", label: "Доля терапевта" },
];

const AdminTherapistsPage = () => {
  const navigate = useNavigate();
  const [therapistList, setTherapistList] = useState([]);

  useEffect(() => {
    client.call(`=>specialist_getSpecialists~`).then((result) => {
      setTherapistList(result);
    });
  }, []);

  return (
    <div>
      <TableContainer
        sx={{
          width: "100%",
          position: "relative",
          marginBottom: "20px",
          marginTop: "10px",
        }}
        className='overflow-y-auto max-h-[calc(100vh-150px)]'
      >
        <Table
          stickyHeader
          aria-labelledby='tableTitle'
          size='small'
        >
          <TableHead>
            <TableRow>
              {therapistList.length > 0 &&
                columns.map((item) => (
                  <TableCell
                    key={item.id}
                    align='left'
                    style={{ fontWeight: "bold" }}
                  >
                    {item.label}
                  </TableCell>
                ))}
              <TableCell
                key='action'
                align='right'
              />
            </TableRow>
          </TableHead>
          <TableBody>
            {therapistList.length > 0 &&
              therapistList?.map((row, index) => {
                return (
                  <TableRow
                    hover
                    tabIndex={-1}
                    key={index.toString()}
                    onClick={() => {
                      navigate(`../r/admin/therapist?id=${row.id}`);
                    }}
                  >
                    {columns?.map((column) => {
                      return (
                        <TableCell
                          key={column.id}
                          align='left'
                          className={styles.rowItemContainer}
                        >
                          {row[column.id]}
                        </TableCell>
                      );
                    })}
                    <TableCell
                      key='edit'
                      align='right'
                      className={styles.rowItemContainer}
                    >
                      <BorderColorTwoToneIcon
                        onClick={() => {
                          navigate(`../r/admin/therapist?id=${row.id}`);
                        }}
                        className='cursor-pointer'
                        color='primary'
                      />
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default AdminTherapistsPage;
