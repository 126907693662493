import { Box, Tab, Tabs } from "@mui/material";
import React, { useState } from "react";
import { ProfileTab } from "./components/ProfileTab";
import { StatisticsTab } from "./components/StatisticsTab";

const EditTherapistPage = () => {
  const url = new URLSearchParams(window.location.search);
  const id = url.get("id");

  const [currentTab, setCurrentTab] = useState(0);

  const handleChangeTab = (event, newValue) => {
    setCurrentTab(newValue);
  };

  return (
    <Box>
      <Tabs
        value={currentTab}
        onChange={handleChangeTab}
        indicatorColor='primary'
        textColor='primary'
      >
        <Tab label='Профайл' />
        <Tab label='Статистика' />
      </Tabs>

      {currentTab === 0 && <ProfileTab therapistId={id} />}
      {currentTab === 1 && <StatisticsTab therapistId={id} />}
    </Box>
  );
};

export default EditTherapistPage;
