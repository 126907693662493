import CloseIcon from "@mui/icons-material/Close";
import {
  Alert,
  Button,
  FormControlLabel,
  Snackbar,
  Switch,
  TextField,
  Tooltip,
} from "@mui/material";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  default as client,
  default as limbo,
} from "../../../services/core/limboclient";

export const ProfileTab = ({ therapistId }) => {
  const navigate = useNavigate();

  const [therapist, setTherapist] = useState(null);
  const [openSnackbar, setOpenSnackbar] = useState({
    isOpen: false,
    alertType: "success",
    message: "",
  });
  const [newNote, setNewNote] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (therapistId) {
      limbo
        .call(
          `=>{
            "therapistDetails": (specialist_getSpecialist ~ {
              "id": "${therapistId}"
            })
          }`
        )
        .then((result) => {
          if (result.error === "not_authorized") {
            navigate("/r/sign-in");
          }
          if (result?.therapistDetails) {
            setTherapist(result.therapistDetails);
          }
          setIsLoading(false);
        });
    }
  }, [therapistId, navigate]);

  const handleUpdateTherapistDetails = () => {
    if (!therapist) return;
    limbo
      .call(
        `=>therapist_updateProfile ~ ${JSON.stringify({
          details: therapist.details,
          specialization: therapist.specialization,
          img: therapist.image,
          therapist: therapist.id,
          searchable: therapist.searchable,
          rate: therapist.rate,
          share: therapist.share,
          availableFrom: therapist.availableFrom,
        })}`
      )
      .then((result) => {
        if (result.error === "not_authorized") {
          navigate("/r/sign-in");
        }
        if (result.success) {
          setOpenSnackbar({
            isOpen: true,
            alertType: "success",
            message: "Дані терапевта були успішно оновлені!",
          });
        } else {
          setOpenSnackbar({
            isOpen: true,
            alertType: "error",
            message: "Дані терапевта НЕ було оновлено, спробуйте ще раз!",
          });
        }
      });
  };

  const handleAddNewNote = () => {
    if (!newNote || !newNote.trim().length) {
      setOpenSnackbar({
        isOpen: true,
        alertType: "error",
        message: "Нотатка не може бути порожньою!",
      });
      setNewNote(null);
      return;
    }

    limbo
      .call(
        `=>therapist_addNote ~ ${JSON.stringify({
          therapist: therapist?.id,
          note: newNote.trim(),
        })}`
      )
      .then((result) => {
        if (result.success) {
          limbo
            .call(
              `=>therapist_details ~ ${JSON.stringify({ id: therapistId })}`
            )
            .then((res2) => {
              if (!!res2) setTherapist(res2);
            });
          setOpenSnackbar({
            isOpen: true,
            alertType: "success",
            message: "Нотатка була успішно додана!",
          });
          setNewNote(null);
        } else {
          setOpenSnackbar({
            isOpen: true,
            alertType: "error",
            message: "Нотатка не була додана, спробуйте ще раз!",
          });
          setNewNote(null);
        }
      });
  };

  const handleDeleteNote = (noteId) => {
    client
      .call(
        `=>note_delete ~ ${JSON.stringify({
          id: noteId,
        })}`
      )
      .then((result) => {
        if (result.success) {
          limbo
            .call(
              `=>therapist_details ~ ${JSON.stringify({ id: therapistId })}`
            )
            .then((res2) => {
              if (!!res2) setTherapist(res2);
            });
          setOpenSnackbar({
            isOpen: true,
            alertType: "success",
            message: "Нотатка була успішно видалена!",
          });
        } else {
          setOpenSnackbar({
            isOpen: true,
            alertType: "error",
            message: "Нотатка не була видалена, спробуйте ще раз!",
          });
        }
      });
  };

  if (isLoading) {
    return <p>Завантаження даних...</p>;
  }
  if (!therapist) {
    return <p>Терапевта не знайдено.</p>;
  }

  return (
    <div className='mt-4'>
      <h2 className='text-3xl font-bold mb-5'>
        {therapist?.firstName} {therapist?.lastName}
      </h2>
      <div className='flex'>
        {therapist?.image && (
          <img
            src={therapist?.image}
            alt='Фото терапевта'
            className='mr-5 h-[400px] rounded-md'
          />
        )}
        <div className='w-full'>
          <div className='flex items-center mt-3'>
            <TextField
              fullWidth
              label='Посилання на зображення'
              value={therapist?.image || ""}
              onChange={(event) =>
                setTherapist({ ...therapist, image: event.target.value })
              }
              className='mt-5'
            />
          </div>
          <div className='flex items-center mt-3'>
            <TextField
              fullWidth
              value={therapist?.details || ""}
              label='Опис'
              id='details'
              onChange={(event) =>
                setTherapist({ ...therapist, details: event.target.value })
              }
              className='mt-5'
              required
            />
          </div>
          <div className='flex items-center mt-3'>
            <TextField
              fullWidth
              value={therapist?.specialization || ""}
              label='Спеціалізація'
              id='specialization'
              onChange={(event) =>
                setTherapist({
                  ...therapist,
                  specialization: event.target.value,
                })
              }
              className='mt-5'
              required
            />
          </div>
          <div className='flex items-center mt-3'>
            <TextField
              fullWidth
              label='Тариф'
              id='rate'
              value={therapist?.rate || ""}
              onChange={(event) =>
                setTherapist({ ...therapist, rate: event.target.value })
              }
              className='mt-5'
              required
            />
          </div>
          <div className='flex items-center mt-3'>
            <TextField
              fullWidth
              label='Доля терапевта'
              id='share'
              value={therapist?.share || ""}
              onChange={(event) =>
                setTherapist({ ...therapist, share: event.target.value })
              }
              className='mt-5'
              required
            />
          </div>

          {/* Дата, з якої терапевт може брати нових клієнтів */}
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DemoContainer components={["DatePicker"]}>
              <DatePicker
                label='Дата з якої терапевт може брати нових клієнтів'
                value={
                  therapist?.availableFrom
                    ? moment(therapist?.availableFrom)
                    : null
                }
                onChange={(newValue) =>
                  setTherapist({
                    ...therapist,
                    availableFrom: newValue,
                  })
                }
                format='DD.MM.YYYY'
                className='mt-3 w-full'
              />
            </DemoContainer>
          </LocalizationProvider>
        </div>
      </div>

      {/* Нотатки */}
      <div className='mt-6'>
        <p className='font-bold mb-2'>Нотатки:</p>
        {therapist?.notes?.length > 0 &&
          therapist?.notes?.map((item) => (
            <div
              key={item.id}
              className='border border-[#a1a1a1] p-2 rounded-lg relative mb-3'
            >
              <Tooltip
                title='Видалити нотатку'
                placement='top-end'
              >
                <CloseIcon
                  color='error'
                  className='absolute top-2 right-2 cursor-pointer'
                  onClick={() => handleDeleteNote(item.id)}
                />
              </Tooltip>
              <p className='w-[90%]'>{item.note}</p>
              <p className='text-sm text-gray flex items-center justify-end mt-2'>
                {moment(new Date(item.createDate)).locale("uk").format("LLL")}
              </p>
            </div>
          ))}

        {newNote !== null && (
          <TextField
            value={newNote}
            variant='outlined'
            size='small'
            onChange={(event) => setNewNote(event.target.value)}
            onBlur={handleAddNewNote}
            className='w-[100%] rounded-lg'
          />
        )}
        <Button
          variant='contained'
          className='mt-5'
          onClick={() => setNewNote("")}
        >
          Додати нотатку
        </Button>
      </div>

      {/* Перемикач "Доступний для вибору" */}
      <FormControlLabel
        control={
          <Switch
            checked={therapist?.searchable || false}
            onChange={(event) =>
              setTherapist({
                ...therapist,
                searchable: event.target.checked,
              })
            }
            className='ml-5'
          />
        }
        label='Терапевт доступний для вибору клієнтам'
        labelPlacement='start'
        className='mt-5'
      />

      {/* Кнопка збереження */}
      <div className='grid grid-cols-2 gap-10 mt-5'>
        <Button
          fullWidth
          variant='contained'
          color='primary'
          onClick={handleUpdateTherapistDetails}
        >
          Зберегти зміни
        </Button>
      </div>

      {/* Повідомлення */}
      <Snackbar
        open={openSnackbar.isOpen}
        autoHideDuration={5000}
        onClose={() => setOpenSnackbar({ ...openSnackbar, isOpen: false })}
      >
        <Alert
          onClose={() => setOpenSnackbar({ ...openSnackbar, isOpen: false })}
          severity={openSnackbar.alertType}
          sx={{ width: "100%" }}
        >
          {openSnackbar.message}
        </Alert>
      </Snackbar>
    </div>
  );
};
