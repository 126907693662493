import React, { useState, useEffect, useCallback, useRef } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Select,
  MenuItem,
  InputAdornment,
} from "@mui/material";
import SearchTwoToneIcon from "@mui/icons-material/SearchTwoTone";
import ClearTwoToneIcon from "@mui/icons-material/ClearTwoTone";
import moment from "moment";
import client from "../../services/core/limboclient";

const columns = [
  { id: "date", label: "Дата" },
  { id: "amount", label: "Сума" },
  { id: "clientName", label: "Ім'я клієнта" },
  { id: "share", label: "Доля спеціаліста" },
  { id: "service", label: "Сервіс" },
  { id: "status", label: "Статус" },
  { id: "method", label: "Платіжний метода" },
  { id: "externalId", label: "Зовнішній ІД" },
  { id: "externalStatus", label: "Зовнішній статус" },
  { id: "specialistLastName", label: "Спеціаліст" },
];

export default function AdminTransactionPage() {
  const tableEl = useRef();
  const [transactions, setTransactions] = useState([]);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [loading, setLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedTherapist, setSelectedTherapist] = useState("");
  const [selectedConsultant, setSelectedConsultant] = useState("");
  const [therapistList, setTherapistList] = useState([]);
  const [consultantList, setConsultantList] = useState([]);

  const loadTransactions = () => {
    if (hasMore) {
      setLoading(true);
      client
        .call(
          `=> payment_getTransactions ~ ${JSON.stringify({
            recipient: selectedTherapist || selectedConsultant,
            phrase: searchQuery,
            page: page,
          })}`
        )
        .then((result) => {
          if (result.length) {
            setTransactions((prev) => [...prev, ...result]);
            if (result.length === 0) {
              setHasMore(false);
            }
          }
          setLoading(false);
        });
    }
  };

  const loadMoreTransactions = () => {
    if (
      tableEl.current.scrollTop + tableEl.current.clientHeight >=
        tableEl.current.scrollHeight &&
      hasMore &&
      !loading
    ) {
      setPage((prevPage) => prevPage + 1);
    }
  };

  useEffect(() => {
    setTransactions([]);
    setPage(1);
    loadTransactions();
  }, [selectedTherapist, selectedConsultant, searchQuery]);

  useEffect(() => {
    const tableRef = tableEl.current;
    tableRef.addEventListener("scroll", loadMoreTransactions);
    return () => {
      tableRef.removeEventListener("scroll", loadMoreTransactions);
    };
  }, []);

  const handleCellView = (value, columnType) => {
    switch (columnType) {
      case "date":
        return moment(new Date(value)).locale("uk").format("LLL");
      case "service":
        return value?.displayVal === "consultation"
          ? "Консультування"
          : "Терапія";
      case "status":
        return (
          {
            settled: "Розділено",
            cancelled: "ВІдмінено",
            created: "В обробці",
            captured: "Сплачено",
          }[value?.displayVal] || value?.displayVal
        );
      default:
        return value;
    }
  };

  useEffect(() => {
    client
      .call(
        `=> {
          "therapists": (therapist_list ~),
          "consultants": (consultant_list ~)
        }`
      )
      .then(({ therapists, consultants }) => {
        setTherapistList(therapists);
        setConsultantList(consultants);
      });
  }, []);

  useEffect(() => {
    loadTransactions();
  }, [page]);

  return (
    <div>
      <h2>Список платіжних транзакцій</h2>
      <div className='filters'>
        <Select
          value={selectedTherapist}
          onChange={(e) => setSelectedTherapist(e.target.value)}
          displayEmpty
          className='filter-select'
          placeholder='Оберіть терапевта'
        >
          <MenuItem value=''>Всі терапевти</MenuItem>
          {therapistList.length > 0 &&
            therapistList?.map((therapist) => (
              <MenuItem
                key={therapist.id}
                value={therapist.id}
              >
                {therapist?.firstName} {therapist?.lastName}
              </MenuItem>
            ))}
        </Select>

        <Select
          value={selectedConsultant}
          onChange={(e) => setSelectedConsultant(e.target.value)}
          displayEmpty
          className='filter-select'
          placeholder='Оберіть консультанта'
        >
          <MenuItem value=''>Всі консультанти</MenuItem>
          {consultantList.map((consultant) => (
            <MenuItem
              key={consultant.id}
              value={consultant.id}
            >
              {consultant?.firstName} {consultant?.lastName}
            </MenuItem>
          ))}
        </Select>

        <TextField
          variant='outlined'
          value={searchQuery}
          className='filter-search'
          onChange={(e) => {
            setSearchQuery(e.target.value);
          }}
          placeholder='Пошук клієнтів...'
          InputProps={{
            startAdornment: (
              <InputAdornment position='start'>
                <SearchTwoToneIcon color='primary' />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position='end'>
                <ClearTwoToneIcon
                  className='cursor-pointer'
                  color='error'
                  onClick={() => searchQuery("")}
                />
              </InputAdornment>
            ),
          }}
        />
      </div>

      <div className='mt-5 overflow-hidden relative'>
        <TableContainer
          sx={{
            maxHeight: "65vh",
            height: "100%",
            position: "relative",
            marginBottom: "20px",
            marginTop: "10px",
          }}
          ref={tableEl}
          id='table'
        >
          <Table
            stickyHeader
            aria-labelledby='tableTitle'
            size='small'
          >
            <TableHead>
              <TableRow>
                {columns.map((item) => (
                  <TableCell
                    key={item.id}
                    align='justify'
                    style={{ fontWeight: "bold" }}
                  >
                    {item.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {transactions.map((row, index) => {
                return (
                  <TableRow
                    hover
                    tabIndex={-1}
                    key={index.toString()}
                    className='cursor-pointer'
                  >
                    {columns.map((column) => (
                      <TableCell
                        key={column.id}
                        align='left'
                        className='rowItemContainer'
                      >
                        {handleCellView(row[column.id], column.id)}
                      </TableCell>
                    ))}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      {transactions.length === 0 && (
        <em className='opacity-60 flex items-center justify-center text-xl'>
          Відсутні транзакції для відображення...
        </em>
      )}
    </div>
  );
}
