import React, { useEffect, useState, useRef, useCallback } from "react";
import {
  Button,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  FormControl,
  MenuItem,
  Select,
  InputLabel,
  Chip,
} from "@mui/material";
import clsx from "clsx";
import styles from "../../../therapy-tests/index.module.scss";
import ContentCopyTwoToneIcon from "@mui/icons-material/ContentCopyTwoTone";
import DeleteForeverTwoToneIcon from "@mui/icons-material/DeleteForeverTwoTone";
import LibraryAddTwoToneIcon from "@mui/icons-material/LibraryAddTwoTone";
import limbo from "../../../../services/core/limboclient";
import NewClientDialog from "../new-client-dialog";
import MuiAlert from "@mui/material/Alert";
import { useNavigate } from "react-router-dom";
import { debounce } from "lodash";

const columns = [
  { id: "name", label: "Ім'я" },
  { id: "phone", label: "Телефон" },
  { id: "status", label: "Статус" },
];

const statusOptions = [
  { id: 1, label: "Active" },
  { id: 2, label: "Await" },
];

const ClientsView = () => {
  const navigate = useNavigate();
  const [clientsList, setClientsList] = useState([]);
  const [requests, setRequests] = useState([]);
  const [isOpenNewClientDialog, setIsOpenNewClientDialog] = useState(false);
  const [paymentUrl, setPaymentUrl] = useState(null);
  const [statusFilter, setStatusFilter] = useState(null);
  const [newClient, setNewClient] = useState({
    name: "",
    phone: "",
    rate: "",
    share: "",
  });
  const [openSnackbar, setOpenSnackbar] = useState({
    isOpen: false,
    alertType: "success",
    message: "",
  });

  const [page, setPage] = useState(1);
  const loadingRef = useRef(false);
  const [hasMore, setHasMore] = useState(true);
  const tableContainerRef = useRef(null);

  useEffect(() => {
    loadClients();
  }, [page]);

  const loadClients = () => {
    if (loadingRef.current || !hasMore) return;
    loadingRef.current = true;

    limbo
      .call(
        `=>{
          "clients" : (client_getMyClients~ ${JSON.stringify({
            page: page,
          })}),
          "requests" : (therapist_getMyTherapyRequests~),
          "paymentPageUrl" : (payment_getPaymentPageDomain ~)
        }`
      )
      .then((result) => {
        if (result?.success !== false) {
          setClientsList((prev) => [...prev, ...result.clients]);
          setRequests(result.requests);
          setPaymentUrl(result.paymentPageUrl);
          setHasMore(result.clients?.length > 0);
          loadingRef.current = false;
        }
      })
      .catch(() => {
        loadingRef.current = false;
      });
  };

  const handleCloseNewClientDialog = () => {
    setIsOpenNewClientDialog(false);
    setNewClient({
      name: "",
      phone: "",
      rate: "",
      share: "",
    });
  };

  const handleCreateNewClient = () => {
    limbo
      .call(
        `
        $cleintRes = client_newClient~${JSON.stringify(newClient)};
        =>client_getMyClients~;`
      )
      .then((result) => {
        setClientsList(result);
      });
    handleCloseNewClientDialog();
  };

  const handleCopyPaymentLink = (url) => {
    navigator.clipboard.writeText(url);
    setOpenSnackbar({
      isOpen: true,
      alertType: "success",
      message: "Посилання на оплату було успішно скопійоване!",
    });
  };

  const handleDeleteUser = (id) => {
    limbo
      .call(
        `
        client_unassignClient ~ {"client": "${id}"};
        =>client_getMyClients~`
      )
      .then((result) => {
        setClientsList(result);
      });
    setOpenSnackbar({
      isOpen: true,
      alertType: "success",
      message: "Клієнта було успішно видалено!",
    });
  };

  const handleCellView = (value, columnType) => {
    switch (columnType) {
      case "phone":
        return Number(value) ? value : "";
      case "status":
        return handleStatusView(value.displayVal);
      default:
        return value;
    }
  };

  const handleStatusView = (status) => {
    const statusMap = {
      inactive: (
        <Chip
          label={status}
          color='error'
          className='capitalize'
        />
      ),
      new: (
        <Chip
          label={status}
          color='primary'
          className='capitalize'
        />
      ),
      await: (
        <Chip
          label={status}
          color='warning'
          className='capitalize'
        />
      ),
      active: (
        <Chip
          label={status}
          color='success'
          className='capitalize'
        />
      ),
    };
    return statusMap[status?.toLowerCase()] || status;
  };

  const filteredClients = statusFilter
    ? clientsList.filter((client) => client.status.val === statusFilter)
    : clientsList;

  const handleScroll = useCallback(
    debounce(() => {
      const tableContainer = tableContainerRef.current;
      if (
        tableContainer &&
        tableContainer.scrollTop + tableContainer.clientHeight >=
          tableContainer.scrollHeight - 50 &&
        hasMore &&
        !loadingRef.current
      ) {
        setPage((prev) => prev + 1);
      }
    }, 200),
    [hasMore]
  );

  useEffect(() => {
    const tableContainer = tableContainerRef.current;
    if (tableContainer) {
      tableContainer.addEventListener("scroll", handleScroll);
    }
    return () => {
      if (tableContainer) {
        tableContainer.removeEventListener("scroll", handleScroll);
      }
      handleScroll.cancel();
    };
  }, [handleScroll]);

  return (
    <div>
      <FormControl
        variant='outlined'
        className='mb-4'
      >
        <InputLabel id='status-filter-label'>Статус</InputLabel>
        <Select
          labelId='status-filter-label'
          value={statusFilter}
          onChange={(e) => setStatusFilter(e.target.value)}
          label='Статус'
          className='min-w-[250px]'
        >
          <MenuItem value={null}>Всі статуси</MenuItem>
          {statusOptions.map((status) => (
            <MenuItem
              value={status.id}
              key={status.id}
            >
              {status.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <TableContainer
        ref={tableContainerRef}
        sx={{
          maxHeight: "60vh",
          width: "100%",
          position: "relative",
          marginBottom: "20px",
        }}
      >
        <Table
          stickyHeader
          aria-labelledby='tableTitle'
          size={"small"}
        >
          <TableHead>
            <TableRow>
              {filteredClients.length > 0 &&
                columns.map((item) => (
                  <TableCell
                    key={item.id}
                    align='left'
                    style={{
                      minWidth: 150,
                      fontWeight: "bold",
                      userSelect: "none",
                    }}
                  >
                    {item.label}
                  </TableCell>
                ))}
              <TableCell
                key='payment-cell'
                align='left'
                style={{ fontWeight: "bold" }}
              >
                Посилання на оплату
              </TableCell>
              <TableCell
                key='delete-cell'
                align='right'
                style={{ fontWeight: "bold" }}
              />
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredClients.length > 0 &&
              filteredClients.map((row) => (
                <TableRow
                  hover
                  tabIndex={-1}
                  key={row.id}
                  onClick={() => navigate(`../r/client/view?id=${row.id}`)}
                >
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      align='left'
                      className={clsx(
                        styles.rowItemContainer,
                        "select-none cursor-pointer"
                      )}
                    >
                      {handleCellView(row[column.id], column.id)}
                    </TableCell>
                  ))}
                  <TableCell
                    align='left'
                    className={clsx(styles.rowItemContainer, "cursor-pointer")}
                  >
                    <span>
                      {paymentUrl +
                        (row.alliance
                          ? "?alliance=" + row.alliance
                          : "?client=" + row.id)}
                    </span>
                    <ContentCopyTwoToneIcon
                      className='ml-3 cursor-pointer'
                      onClick={(event) => {
                        event.stopPropagation();
                        handleCopyPaymentLink(
                          paymentUrl +
                            (row.alliance
                              ? "?alliance=" + row.alliance
                              : "?client=" + row.id)
                        );
                      }}
                      color='warning'
                    />
                  </TableCell>
                  <TableCell
                    align='right'
                    className={styles.rowItemContainer}
                  >
                    <DeleteForeverTwoToneIcon
                      color='error'
                      className='ml-2 cursor-pointer'
                      onClick={(event) => {
                        event.stopPropagation();
                        handleDeleteUser(row.id);
                      }}
                    />
                  </TableCell>
                </TableRow>
              ))}
            {requests?.length > 0
              ? requests.map((request) => (
                  <TableRow key={request.id}>
                    <TableCell
                      align='left'
                      className={clsx(
                        styles.rowItemContainer,
                        "cursor-pointer"
                      )}
                    >
                      {request.name}
                    </TableCell>
                    <TableCell
                      align='left'
                      className={clsx(
                        styles.rowItemContainer,
                        "cursor-pointer"
                      )}
                    >
                      <LibraryAddTwoToneIcon
                        color='primary'
                        className='ml-3 cursor-pointer'
                        onClick={() => {
                          limbo
                            .call({
                              query: `
                                $acceptRes = therapist_acceptTherapyRequest ~ {
                                  "id" : $id
                                };
                                =>{
                                  "acceptRes" : $acceptRes,
                                  "clients" : (client_getMyClients~),
                                  "requests" : (therapist_getMyTherapyRequests~)
                                };
                              `,
                              params: {
                                id: request.id,
                              },
                            })
                            .then((r) => {
                              setClientsList(r.clients);
                              setRequests(r.requests);
                            });
                        }}
                      />
                    </TableCell>
                    <TableCell
                      align='left'
                      className={clsx(
                        styles.rowItemContainer,
                        "cursor-pointer"
                      )}
                    >
                      <DeleteForeverTwoToneIcon
                        color='error'
                        className='ml-2 cursor-pointer'
                        onClick={(event) => {
                          event.stopPropagation();
                          client
                            .call({
                              query: `
                              $delRes = therapist_rejectTherapyRequest ~ {
                                "id" : $id
                              };
                              =>{
                                "deleteResult" : $delRes,
                                "clients" : (client_getMyClients~),
                                "requests" : (therapist_getMyTherapyRequests~)
                              };
                            `,
                              params: {
                                id: request.id,
                              },
                            })
                            .then((r) => {
                              setClientsList(r.clients);
                              setRequests(r.requests);
                            });
                        }}
                      />
                    </TableCell>
                  </TableRow>
                ))
              : ""}
          </TableBody>
        </Table>
      </TableContainer>
      <NewClientDialog
        isOpen={isOpenNewClientDialog}
        onClose={handleCloseNewClientDialog}
        newClient={newClient}
        setNewClient={(value) => setNewClient(value)}
        handleCreateNewClient={handleCreateNewClient}
      />
      <Snackbar
        open={openSnackbar.isOpen}
        autoHideDuration={5000}
        onClose={() => setOpenSnackbar({ ...openSnackbar, isOpen: false })}
      >
        <MuiAlert
          onClose={() => setOpenSnackbar({ ...openSnackbar, isOpen: false })}
          severity={openSnackbar.alertType}
          sx={{ width: "100%" }}
        >
          {openSnackbar.message}
        </MuiAlert>
      </Snackbar>
    </div>
  );
};

export default ClientsView;
