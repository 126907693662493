import React, { useEffect, useState } from "react";
import {
  Button,
  Chip,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  MenuItem,
  OutlinedInput,
  Select,
  Snackbar,
  TextField,
  Tooltip,
} from "@mui/material";
import { ReactComponent as ViberIcon } from "../../../../assets/viber.svg";
import { ReactComponent as TelegramIcon } from "../../../../assets/telegram.svg";
import { ReactComponent as WhatsAppIcon } from "../../../../assets/whatsApp.svg";
import SettingsTwoToneIcon from "@mui/icons-material/SettingsTwoTone";
import CloseIcon from "@mui/icons-material/Close";
import client from "../../../../services/core/limboclient";
import MuiAlert from "@mui/material/Alert";
import moment from "moment";
import clsx from "clsx";

const AppointmentModal = ({ isOpen, onClose, selectedAppointment }) => {
  const [appointment, setAppointment] = useState(selectedAppointment);
  const [notes, setNotes] = useState([]);
  const [newNote, setNewNote] = useState(null);
  const [openSnackbar, setOpenSnackbar] = useState({
    isOpen: false,
    alertType: "success",
    message: "",
  });
  const [therapistList, setTherapistList] = useState([]);
  const [selectedTherapist, setSelectedTherapist] = useState(null);
  const [isOpenStatusDialog, setIsOpenStatusDialog] = useState(false);

  useEffect(() => {
    if (isOpen) {
      if (selectedAppointment && newNote === null) {
        client.call(`=>therapist_list~`).then((result) => {
          setTherapistList(result);
        });
      }
    } else {
      setSelectedTherapist(null);
      setNewNote(null);
    }
  }, [isOpen]);

  useEffect(() => {
    if (isOpen && selectedAppointment && newNote === null) {
      client
        .call(
          `=>appointment_getMyNotes ~ ${JSON.stringify({
            appointment: selectedAppointment?.id,
          })}`
        )
        .then((result) => setNotes(result));
      setAppointment(selectedAppointment);
    }
  }, [selectedAppointment, newNote]);

  const handleUpdateReferrer = () => {
    if (appointment?.referrer?.length > 0) {
      client
        .call(
          `=>appointment_updateAppointment ~ ${JSON.stringify({
            appointment: appointment?.id,
            referrer: appointment?.referrer,
          })}`
        )
        .then((result) => {
          if (result.success) {
            setOpenSnackbar({
              isOpen: true,
              alertType: "success",
              message: "Дані заявки були успішно оновлені!",
            });
          } else {
            setOpenSnackbar({
              isOpen: true,
              alertType: "error",
              message: "Дані заявки не були оновлені, спробуйте ще раз!",
            });
          }
        });
    } else {
      setOpenSnackbar({
        isOpen: true,
        alertType: "error",
        message: "Дані заявки не були оновлені, спробуйте ще раз!",
      });
    }
  };

  const handleAddNewNote = () => {
    if (newNote?.length > 0) {
      client
        .call(
          `=>appointment_addNote  ~ ${JSON.stringify({
            appointment: appointment?.id,
            note: newNote,
          })}`
        )
        .then((result) => {
          if (result.success) {
            setOpenSnackbar({
              isOpen: true,
              alertType: "success",
              message: "Нотатка була успішно додана!",
            });
            setNewNote(null);
          } else {
            setOpenSnackbar({
              isOpen: true,
              alertType: "error",
              message: "Нотатка не була додана, спробуйте ще раз!",
            });
            setNewNote(null);
          }
        });
    } else {
      setOpenSnackbar({
        isOpen: true,
        alertType: "error",
        message: "Нотатка не була додана, спробуйте ще раз!",
      });
      setNewNote(null);
    }
  };

  const handleDeleteNote = (noteId) => {
    client
      .call(
        `=>note_delete ~ ${JSON.stringify({
          id: noteId,
        })}`
      )
      .then((result) => {
        if (result.success) {
          setOpenSnackbar({
            isOpen: true,
            alertType: "success",
            message: "Нотатка була успішно видалена!",
          });
        } else {
          setOpenSnackbar({
            isOpen: true,
            alertType: "error",
            message: "Нотатка не була видалена, спробуйте ще раз!",
          });
        }
      });
    client
      .call(
        `=>appointment_getMyNotes ~ ${JSON.stringify({
          appointment: selectedAppointment?.id,
        })}`
      )
      .then((result) => setNotes(result));
  };

  const handleMessengerView = (howToCall) => {
    switch (howToCall) {
      case "viber":
        return (
          <div className='flex items-center'>
            <p>Viber</p>
            <ViberIcon className='h-[30px]' />
          </div>
        );
      case "telegram":
        return (
          <div className='flex items-center'>
            <p>Telegram</p>
            <TelegramIcon className='h-[30px]' />
          </div>
        );
      case "whatsUp":
        return (
          <div className='flex items-center'>
            <p>WhatsApp</p>
            <WhatsAppIcon className='h-[35px]' />
          </div>
        );
      default:
        return howToCall;
    }
  };

  const handleStatusView = (status, bigSize = false) => {
    switch (Number(status)) {
      case 0:
        return (
          <Chip
            label='Нова заявка'
            color='info'
            size={bigSize ? "medium" : "small"}
            className={clsx("w-full", bigSize && "cursor-pointer")}
          />
        );
      case 1:
        return (
          <Chip
            label='Прийнята заявка'
            color='success'
            size={bigSize ? "medium" : "small"}
            className={clsx("w-full", bigSize && "cursor-pointer")}
          />
        );
      case -1:
        return (
          <Chip
            label='Відхилена заявка'
            color='error'
            size={bigSize ? "medium" : "small"}
            className={clsx("w-full", bigSize && "cursor-pointer")}
          />
        );
      default:
        return status;
    }
  };

  const addNewClient = () => {
    client
      .call(
        `=>client_create ~ ${JSON.stringify({
          appointment: appointment?.id,
          ...(selectedTherapist && { therapist: selectedTherapist.id }),
        })}`
      )
      .then((result) => {
        if (result.success) {
          setOpenSnackbar({
            isOpen: true,
            alertType: "success",
            message: `Клієнта було успішно створено!`,
          });
          setSelectedTherapist(null);
        } else {
          setOpenSnackbar({
            isOpen: true,
            alertType: "error",
            message: "Клієнта не було створено, спробуйте ще раз!",
          });
          setSelectedTherapist(null);
        }
      });
  };

  const handleChangeAppointmentStatus = (newStatusCode) => {
    client
      .call(
        `=>appointment_updateAppointment ~ ${JSON.stringify({
          appointment: appointment?.id,
          status: newStatusCode,
        })}`
      )
      .then((result) => {
        if (result.success) {
          setOpenSnackbar({
            isOpen: true,
            alertType: "success",
            message: `Статус заявки було успішно змінено!`,
          });
          setIsOpenStatusDialog(false);
          setAppointment({
            ...appointment,
            status: { ...appointment.status, val: newStatusCode },
          });
        } else {
          setOpenSnackbar({
            isOpen: true,
            alertType: "error",
            message: "Статус НЕ було змінено, спробуйте ще раз!",
          });
        }
      });
  };

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      fullWidth
      maxWidth='md'
    >
      <DialogTitle className='text-center !font-bold'>
        Заявка від {appointment?.name}
      </DialogTitle>
      <DialogContent>
        <div className='grid grid-cols-2 gap-x-10 w-[70%]'>
          <p className='font-bold'>Ім'я:</p>
          <p>{appointment?.name}</p>
        </div>
        <div className='grid grid-cols-2 gap-x-10 w-[70%] mt-3'>
          <p className='font-bold'>Телефон:</p>
          <p>{appointment?.phone}</p>
        </div>
        <div className='grid grid-cols-2 gap-x-10 w-[70%] mt-3'>
          <p className='font-bold'>Обраний спосіб комунікації:</p>
          <p>{handleMessengerView(appointment?.howToCall)}</p>
        </div>
        <div className='grid grid-cols-2 gap-x-10 w-[70%] mt-3'>
          <p className='font-bold'>Статус заявки:</p>
          <p className='flex items-center'>
            {handleStatusView(appointment?.status?.val)}
            <SettingsTwoToneIcon
              className='!ml-3 cursor-pointer'
              color={
                appointment?.status?.val === 0
                  ? "info"
                  : appointment?.status?.val === 1
                  ? "success"
                  : "error"
              }
              onClick={() => setIsOpenStatusDialog(true)}
            />
          </p>
        </div>
        <div className='grid grid-cols-2 gap-x-10 w-[70%] mt-3'>
          <p className='font-bold'>Як про нас дізнались:</p>
          <TextField
            value={appointment?.referrer}
            variant='outlined'
            size='small'
            onChange={(event) =>
              setAppointment({ ...appointment, referrer: event.target.value })
            }
            onBlur={handleUpdateReferrer}
            className='w-[100%]'
          />
        </div>
        <div className='mt-3'>
          <p className='font-bold mb-2'>Нотатки:</p>
          {notes?.length > 0 &&
            notes.map((item) => {
              return (
                <div className='border border-[#a1a1a1] p-2 rounded-lg relative mb-3'>
                  <Tooltip
                    title='Видалити нотатку'
                    placement='top-end'
                  >
                    <CloseIcon
                      color='error'
                      className='absolute top-2 right-2 cursor-pointer'
                      onClick={() => handleDeleteNote(item.id)}
                    />
                  </Tooltip>
                  <p className='w-[90%]'>{item.note}</p>
                  <p className='text-sm text-gray flex items-center justify-end mt-2'>
                    {moment(new Date(item.createDate))
                      .locale("uk")
                      ?.format("LLL")}
                  </p>
                </div>
              );
            })}
          {newNote !== null && (
            <TextField
              value={newNote}
              variant='outlined'
              size='small'
              onChange={(event) => setNewNote(event.target.value)}
              onBlur={handleAddNewNote}
              className='w-[100%] !rounded-lg'
            />
          )}
          <Button
            variant='contained'
            className='!mt-5'
            onClick={() => setNewNote("")}
          >
            Додати нотатку
          </Button>
        </div>
        <div className='flex items-center justify-between w-[100%] mt-3'>
          <p className='font-bold'>Швидке створення клієнта:</p>
          <FormControl
            variant='standard'
            key='select-level'
          >
            <Select
              size='small'
              value={selectedTherapist}
              input={<OutlinedInput />}
              displayEmpty
              inputProps={{ "aria-label": "Without label" }}
              className='!w-[300px]'
              renderValue={(selected) => {
                if (!selected)
                  return <p className='opacity-70'>Оберіть терапевта</p>;

                return (
                  <>
                    {selected?.firstName} {selected?.lastName}
                  </>
                );
              }}
              onChange={(event) => setSelectedTherapist(event.target.value)}
            >
              {therapistList.length > 0 && therapistList?.map((theparist, index) => (
                <MenuItem
                  value={theparist}
                  key={index.toString()}
                >
                  {theparist?.firstName} {theparist?.lastName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Button
            variant='contained'
            onClick={addNewClient}
          >
            Створити клієнта
          </Button>
        </div>
      </DialogContent>
      <Snackbar
        open={openSnackbar.isOpen}
        autoHideDuration={5000}
        onClose={() => setOpenSnackbar({ ...openSnackbar, isOpen: false })}
      >
        <MuiAlert
          onClose={() => setOpenSnackbar({ ...openSnackbar, isOpen: false })}
          severity={openSnackbar.alertType}
          sx={{ width: "100%" }}
        >
          {openSnackbar.message}
        </MuiAlert>
      </Snackbar>
      <Dialog
        open={isOpenStatusDialog}
        onClose={() => setIsOpenStatusDialog(false)}
        fullWidth
        maxWidth='sm'
      >
        <DialogTitle className='text-center !font-bold'>
          Змінити статус заявки на
        </DialogTitle>
        <DialogContent>
          <div className='flex items-center gap-5'>
            {[-1, 0, 1].map((item) => {
              if (item !== appointment?.status?.val) {
                return (
                  <div
                    className='w-full'
                    onClick={() => handleChangeAppointmentStatus(item)}
                  >
                    {handleStatusView(item, true)}
                  </div>
                );
              }
            })}
          </div>
        </DialogContent>
      </Dialog>
    </Dialog>
  );
};

export default AppointmentModal;
