import {
  Alert,
  Box,
  Button,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import moment from "moment";
import React, { useEffect, useState } from "react";
import client from "../../../services/core/limboclient";

export const StatisticsTab = ({ therapistId }) => {
  const [fromDate, setFromDate] = useState(moment().subtract(14, "days"));
  const [toDate, setToDate] = useState(moment().add(14, "days"));

  const [sessions, setSessions] = useState([]);
  const [sessionCounts, setSessionCounts] = useState([]);
  const [clientsCount, setClientsCount] = useState([]);
  const [openSnackbar, setOpenSnackbar] = useState({
    isOpen: false,
    alertType: "success",
    message: "",
  });

  const getStatisticsData = () => {
    if (!therapistId) return;

    const fromStr = fromDate.format("DD.MM.YYYY");
    const toStr = toDate.format("DD.MM.YYYY");

    client
      .call(
        `=>clientSession_getSpecialistSessions ~ {
          "specialist": "${therapistId}",
          "from": "${fromStr}",
          "to": "${toStr}"
        }`
      )
      .then((result) => {
        if (Array.isArray(result)) {
          setSessions(result);
        } else {
          setSessions([]);
        }
      })
      .catch(() => setSessions([]));

    client
      .call(
        `=>clientSession_getSpecialistSessionCounts ~ {
          "specialist": "${therapistId}",
          "from": "${fromStr}",
          "to": "${toStr}"
        }`
      )
      .then((counts) => {
        if (counts) {
          setSessionCounts(counts);
        } else {
          setSessionCounts({});
        }
      })
      .catch(() => setSessionCounts({}));

    client
      .call(
        `=>client_getClientCounts ~ ${JSON.stringify({
          specialist: therapistId,
        })}`
      )
      .then((result) => {
        if (result.success !== false) {
          setClientsCount(result);
        }
      })
      .catch(() => {});
  };

  useEffect(() => {
    getStatisticsData();
  }, [therapistId]);

  const handleApplyDates = () => {
    getStatisticsData();
  };

  return (
    <Box
      mt={3}
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "calc(100vh - 150px)",
      }}
    >
      <Typography
        variant='h5'
        fontWeight='bold'
        mb={3}
      >
        Статистика
      </Typography>

      <LocalizationProvider dateAdapter={AdapterMoment}>
        <Box
          display='flex'
          gap={2}
          mb={2}
        >
          <DatePicker
            label='Від'
            value={fromDate}
            onChange={(newValue) => setFromDate(newValue)}
            format='DD.MM.YYYY'
          />
          <DatePicker
            label='До'
            value={toDate}
            onChange={(newValue) => setToDate(newValue)}
            format='DD.MM.YYYY'
          />
          <Button
            variant='contained'
            onClick={handleApplyDates}
          >
            Застосувати
          </Button>
        </Box>
      </LocalizationProvider>

      <Box mb={3}>
        <Typography
          variant='subtitle1'
          fontWeight='bold'
        >
          Клієнти:
        </Typography>
        {clientsCount?.length > 0 ? (
          clientsCount.map((count) => {
            if (count?.status?.val === 1) {
              return <div key={count?.status?.val}>Активні: {count.qty}</div>;
            }
            if (count?.status?.val === 2) {
              return <div key={count?.status?.val}>Чекають: {count.qty}</div>;
            }
            return null;
          })
        ) : (
          <em>Немає інформації про клієнтів.</em>
        )}
      </Box>

      <Box mb={2}>
        <Typography
          variant='subtitle1'
          fontWeight='bold'
        >
          Сесії (за статусами):
        </Typography>
        <Box>
          <div>Відмінені: {sessionCounts.canceled}</div>
          <div>Відбулись: {sessionCounts.completed}</div>
          <div>Заплановані: {sessionCounts.planned}</div>
        </Box>
      </Box>

      <Typography
        variant='subtitle1'
        fontWeight='bold'
        mb={1}
      >
        Список сесій:
      </Typography>
      <TableContainer
        sx={{
          flex: 1,
          overflow: "auto",
        }}
      >
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>Дата</TableCell>
              <TableCell>Статус</TableCell>
              <TableCell>Рейт</TableCell>
              <TableCell>Доля</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sessions?.length > 0 ? (
              sessions.map((session, idx) => (
                <TableRow key={idx}>
                  <TableCell>{session.date || ""}</TableCell>
                  <TableCell>{session.status?.displayVal || ""}</TableCell>
                  <TableCell>{session.rate || ""}</TableCell>
                  <TableCell>{session.specialistShare || ""}</TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={4}>
                  <em>Сесії за вказаний період відсутні.</em>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <Snackbar
        open={openSnackbar.isOpen}
        autoHideDuration={5000}
        onClose={() => setOpenSnackbar({ ...openSnackbar, isOpen: false })}
      >
        <Alert
          severity={openSnackbar.alertType}
          onClose={() => setOpenSnackbar({ ...openSnackbar, isOpen: false })}
          sx={{ width: "100%" }}
        >
          {openSnackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};
