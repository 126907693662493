import React, { useEffect, useState } from "react";
import {
  Button,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
} from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import DeleteForeverTwoToneIcon from "@mui/icons-material/DeleteForeverTwoTone";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import client from "../../../../services/core/limboclient";
import moment from "moment";
import { DateCalendar, PickersDay } from "@mui/x-date-pickers";

const columns = [
  { id: "rate", label: "Тариф" },
  { id: "date", label: "Дата" },
  { id: "status", label: "Статус" },
];

const statuses = {
  canceled: "0",
  completed: "1",
  planned: "2",
};

const SessionTab = ({ allianceID }) => {
  const [sessions, setSessions] = useState([]);
  const [openSnackbar, setOpenSnackbar] = useState({
    isOpen: false,
    alertType: "success",
    message: "",
  });
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedSession, setSelectedSession] = useState(null);
  const [sessionForm, setSessionForm] = useState(null);

  const [scheduleForm, setScheduleForm] = useState({
    startDate: null,
    endDate: null,
    mon: "none",
    tue: "none",
    wed: "none",
    thu: "none",
    fri: "none",
    sat: "none",
    sun: "none",
  });

  const getSessions = () => {
    if (!allianceID) return;
    client
      .call(
        `=>clientSession_getMySessions~ {
          "alliance": "${allianceID}"
        }`
      )
      .then((result) => {
        if (Array.isArray(result)) {
          setSessions(result);
        } else {
          setSessions([]);
        }
      });
  };

  const getSchedule = () => {
    if (!allianceID) return;
    client
      .call(
        `=>clientSession_getSchedule~ {
          "alliance": "${allianceID}"
        }`
      )
      .then((res) => {
        if (res?.success !== false) {
          setScheduleForm({
            startDate: res.startDate ? moment(res.startDate) : null,
            endDate: res.endDate ? moment(res.endDate) : null,
            mon: res.val?.mon || "none",
            tue: res.val?.tue || "none",
            wed: res.val?.wed || "none",
            thu: res.val?.thu || "none",
            fri: res.val?.fri || "none",
            sat: res.val?.sat || "none",
            sun: res.val?.sun || "none",
          });
        }
      });
  };

  const saveSchedule = () => {
    const payload = {
      alliance: allianceID,
      startDate: scheduleForm.startDate
        ? new Date(scheduleForm.startDate).toLocaleDateString()
        : "",
      endDate: scheduleForm.endDate
        ? new Date(scheduleForm.endDate).toLocaleDateString()
        : "",
      schedule: {
        mon: scheduleForm.mon,
        tue: scheduleForm.tue,
        wed: scheduleForm.wed,
        thu: scheduleForm.thu,
        fri: scheduleForm.fri,
        sat: scheduleForm.sat,
        sun: scheduleForm.sun,
      },
    };

    client
      .call(`=>clientSession_setSchedule ~ ${JSON.stringify(payload)}`)
      .then((res) => {
        if (res.success) {
          setOpenSnackbar({
            isOpen: true,
            alertType: "success",
            message: "Розклад успішно збережено!",
          });
          getSchedule();
        } else {
          setOpenSnackbar({
            isOpen: true,
            alertType: "error",
            message: "Не вдалося зберегти розклад, спробуйте ще раз!",
          });
        }
      })
      .catch(() => {
        setOpenSnackbar({
          isOpen: true,
          alertType: "error",
          message: "Сталася помилка при збереженні розкладу!",
        });
      });
  };

  const handleCellView = (value, columnType) => {
    switch (columnType) {
      case "date":
        return value ? value : "";
      case "status":
        return value?.displayVal || "";
      default:
        return value;
    }
  };

  const deleteSession = (id) => {
    client.call(`=>client_deleteSession ~ {"session": "${id}"}`).then((res) => {
      if (res.success === true) {
        setOpenSnackbar({
          isOpen: true,
          alertType: "success",
          message: "Сесію було успішно видалено!",
        });
        getSessions();
      } else {
        setOpenSnackbar({
          isOpen: true,
          alertType: "error",
          message: "Сесію не було видалено, спробуйте ще раз!",
        });
      }
    });
  };

  const handleDialogOpen = (row) => {
    if (row) {
      setSelectedSession(row);
      setSessionForm({ ...row });
    } else {
      setSelectedSession(null);
      setSessionForm({
        rate: "",
        scheduled: "",
        status: { val: "", displayVal: "" },
      });
    }
    setOpenDialog(true);
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
    setSelectedSession(null);
    setSessionForm(null);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setSessionForm((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const saveSession = () => {
    const sessionData = {
      status: Number(sessionForm.status.val),
      date: sessionForm.date,
      rate: Number(sessionForm.rate),
      specialistShare: Number(sessionForm.specialistShare),
    };

    client
      .call(
        `=>clientSession_setMySession ~ ${JSON.stringify({
          alliance: allianceID,
          ...sessionData,
        })}`
      )
      .then((res) => {
        if (res.success === true) {
          setOpenSnackbar({
            isOpen: true,
            alertType: "success",
            message: "Сесію було успішно створено/оновлено!",
          });
          getSessions();
          handleDialogClose();
        } else {
          setOpenSnackbar({
            isOpen: true,
            alertType: "error",
            message: "Сесію не було оновлено, спробуйте ще раз!",
          });
        }
      });
  };

  useEffect(() => {
    getSessions();
    getSchedule();
  }, [allianceID]);

  return (
    <div className='border border-[#a1a1a1] rounded-lg p-5 w-full mt-5'>
      <div className='mb-8 rounded-lg'>
        <div className='flex justify-between mb-4'>
          <h2 className='font-bold text-xl mb-3'>Розклад</h2>
          <Button
            variant='contained'
            className='mt-4'
            onClick={saveSchedule}
          >
            Зберегти розклад
          </Button>
        </div>

        <div className='flex flex-col gap-4 md:flex-row md:items-center md:gap-6'>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <div>
              <p className='text-sm font-semibold'>Дата початку:</p>
              <DatePicker
                value={scheduleForm.startDate}
                onChange={(newValue) => {
                  return setScheduleForm((prev) => ({
                    ...prev,
                    startDate: newValue ? newValue : null,
                  }));
                }}
                format='DD.MM.YYYY'
                displayWeekNumber
                slots={{
                  day: (props) => {
                    const { day, outsideCurrentMonth, ...other } = props;

                    const weekNumber = day.isoWeek();
                    const isEvenWeek = weekNumber % 2 === 0;

                    return (
                      <PickersDay
                        {...other}
                        outsideCurrentMonth={outsideCurrentMonth}
                        day={day}
                        sx={{
                          backgroundColor: isEvenWeek ? "#87CEFA" : "#ffffba",
                        }}
                      />
                    );
                  },
                }}
              />
            </div>
            <div>
              <p className='text-sm font-semibold'>Дата закінчення:</p>
              <DatePicker
                value={scheduleForm.endDate}
                onChange={(newValue) =>
                  setScheduleForm((prev) => ({
                    ...prev,
                    endDate: newValue ? newValue : null,
                  }))
                }
                format='DD.MM.YYYY'
                displayWeekNumber
                slots={{
                  day: (props) => {
                    const { day, outsideCurrentMonth, ...other } = props;

                    const weekNumber = day.isoWeek();
                    const isEvenWeek = weekNumber % 2 === 0;

                    return (
                      <PickersDay
                        {...other}
                        outsideCurrentMonth={outsideCurrentMonth}
                        day={day}
                        sx={{
                          backgroundColor: isEvenWeek ? "#87CEFA" : "#ffffba",
                        }}
                      />
                    );
                  },
                }}
              />
            </div>
          </LocalizationProvider>
        </div>

        <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 xl:grid-cols-7 gap-4 mt-4 mb-4'>
          {["mon", "tue", "wed", "thu", "fri", "sat", "sun"].map((day) => (
            <FormControl
              fullWidth
              key={day}
            >
              <InputLabel>{day.toUpperCase()}</InputLabel>
              <Select
                value={scheduleForm[day]}
                label={day.toUpperCase()}
                onChange={(e) =>
                  setScheduleForm((prev) => ({
                    ...prev,
                    [day]: e.target.value,
                  }))
                }
                sx={{
                  backgroundColor:
                    scheduleForm[day] === "all"
                      ? "#d8ffba"
                      : scheduleForm[day] === "even"
                      ? "lightblue"
                      : scheduleForm[day] === "odd"
                      ? "#ffffba"
                      : scheduleForm[day] === "none"
                      ? "#ffbaba"
                      : "transparent",
                }}
              >
                <MenuItem value='all'>Щотижня</MenuItem>
                <MenuItem value='even'>Парні тижні</MenuItem>
                <MenuItem value='odd'>Непарні тижні</MenuItem>
                <MenuItem value='none'>Відсутня</MenuItem>
              </Select>
            </FormControl>
          ))}
        </div>
      </div>

      <div className='flex items-center justify-between'>
        <p className='font-bold text-xl'>Сесії</p>
        <Button
          onClick={() => handleDialogOpen(null)}
          variant='contained'
        >
          Створити сесію
        </Button>
      </div>
      <TableContainer
        sx={{
          width: "100%",
          position: "relative",
          marginBottom: "20px",
          marginTop: "10px",
        }}
      >
        <Table
          stickyHeader
          size={"small"}
        >
          <TableHead>
            <TableRow>
              {columns.map((item) => (
                <TableCell
                  key={item.id}
                  align='left'
                  style={{ fontWeight: "bold" }}
                >
                  {item.label}
                </TableCell>
              ))}
              <TableCell
                key='action'
                align='right'
              />
            </TableRow>
          </TableHead>
          <TableBody>
            {sessions?.map((row, index) => {
              return (
                <TableRow
                  hover
                  tabIndex={-1}
                  key={index.toString()}
                  onClick={() => handleDialogOpen(row)}
                  style={{ cursor: "pointer" }}
                >
                  {columns?.map((column) => {
                    const value = row[column.id];
                    return (
                      <TableCell
                        key={column.id}
                        align='left'
                      >
                        {handleCellView(value, column.id)}
                      </TableCell>
                    );
                  })}
                  <TableCell
                    key='edit'
                    align='right'
                    onClick={(e) => e.stopPropagation()}
                  >
                    <Tooltip title={`Видалити сесію`}>
                      {row.isAccedintal && (
                        <DeleteForeverTwoToneIcon
                          color='error'
                          className='ml-2 cursor-pointer'
                          onClick={(e) => {
                            e.stopPropagation();
                            deleteSession(row.id);
                          }}
                        />
                      )}
                    </Tooltip>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
        {sessions?.length === 0 && (
          <em className='opacity-70 text-xl mt-5 flex justify-center'>
            Сесії відсутні...
          </em>
        )}
      </TableContainer>
      <Dialog
        open={openDialog}
        onClose={handleDialogClose}
        maxWidth='sm'
        fullWidth
      >
        <DialogTitle>
          {selectedSession ? "Редагувати Сесію" : "Створити Сесію"}
        </DialogTitle>
        <DialogContent dividers>
          {sessionForm && (
            <div>
              <TextField
                label='Тариф'
                value={sessionForm.rate || ""}
                name='rate'
                onChange={handleInputChange}
                fullWidth
                margin='normal'
              />
              <TextField
                label='Доля терапевта'
                value={sessionForm.specialistShare || ""}
                name='specialistShare'
                onChange={handleInputChange}
                fullWidth
                margin='normal'
              />
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DatePicker
                  value={moment(sessionForm?.date, "DD.MM.YYYY") || ""}
                  onChange={(value) => {
                    setSessionForm((prevState) => ({
                      ...prevState,
                      date: value.format("DD.MM.YYYY"),
                    }));
                  }}
                  format='DD.MM.YYYY'
                  sx={{
                    display: "flex",
                  }}
                />
              </LocalizationProvider>

              <FormControl
                fullWidth
                margin='normal'
              >
                <InputLabel id='status-label'>Статус</InputLabel>
                <Select
                  labelId='status-label'
                  id='status'
                  value={sessionForm.status?.val?.toString() || ""}
                  name='status'
                  label='Статус'
                  onChange={(e) => {
                    const value = e.target.value;
                    setSessionForm((prevState) => ({
                      ...prevState,
                      status: {
                        val: value,
                        displayVal: statuses[value],
                      },
                    }));
                  }}
                >
                  <MenuItem value={0}>Відмінена</MenuItem>
                  <MenuItem value={1}>Відбулась</MenuItem>
                  <MenuItem value={2}>Запланована</MenuItem>
                </Select>
              </FormControl>
            </div>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose}>Закрити</Button>
          <Button
            variant='contained'
            onClick={saveSession}
          >
            {selectedSession ? "Зберегти зміни" : "Створити Сесію"}
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={openSnackbar.isOpen}
        autoHideDuration={5000}
        onClose={() => setOpenSnackbar({ ...openSnackbar, isOpen: false })}
      >
        <MuiAlert
          onClose={() => setOpenSnackbar({ ...openSnackbar, isOpen: false })}
          severity={openSnackbar.alertType}
          sx={{ width: "100%" }}
        >
          {openSnackbar.message}
        </MuiAlert>
      </Snackbar>
    </div>
  );
};

export default SessionTab;
